var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"desktop-container",style:(_vm.stretch ? 'height: calc(100vh - 24px);' : 'height: calc(100vh - 48px);')},[_c('div',{staticClass:"backdrop"}),_c('div',{ref:"desktop",staticClass:"desktop"},_vm._l((_vm.windows),function(window,index){return _c('Window',{key:index,attrs:{"index":index,"id":window.id,"title":window.title,"initialWidth":window.width,"initialHeight":window.height,"center":window.center,"embed":window.embed,"video":window.video}},[(window.embed)?[_c('iframe',{attrs:{"id":window.id,"src":window.embed,"frameborder":"0"}})]:_vm._e(),(window.component)?[_c(window.component,{tag:"component"})]:_vm._e(),(window.image)?[_c('div',{staticStyle:{"overflow":"hidden"}},[_c('img',{staticClass:"content-image",staticStyle:{"border-radius":"8px","width":"100%","max-height":"100%"},attrs:{"src":window.image}})])]:_vm._e(),(window.video)?[_c('div',{staticStyle:{"overflow":"hidden","width":"100%","height":"100%' background: black","border-radius":"8px"}},[_c('video',{staticClass:"video",attrs:{"id":window.id,"src":require(("@/content/" + (window.video))),"width":"100%","height":"100%","autoplay":"","controls":"","muted":""},domProps:{"muted":true}})])]:_vm._e(),(window.casestudy)?[_c('div',{staticStyle:{"overflow":"auto","border-radius":"8px","padding-right":"12px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require(("@/content/" + (window.casestudy)))}})])]:_vm._e()],2)}),1),_c('Dock',{attrs:{"hide":_vm.fullscreen}},_vm._l((_vm.dockItems),function(item,index){return _c('div',{key:index,staticClass:"dock-item",style:(((item.newtab && 'cursor: pointer;') + " " + (index === _vm.dividerIndex && 'margin-left: 28px;'))),on:{"click":function($event){item.link
          ? _vm.window.open(item.link, '_blank')
          : _vm.pushWindow({
              title: item.label || 'Title',
              link: item.link || null,
              embed: item.embed || null, // String
              component: item.component || null, // Component
              image: item.image || null, // String
              width: item.windowWidth || 600, // Number
              height: item.windowHeight || 400, // Nmuber
              positionX: item.windowPositionX || _vm.getRandomX(), // Number
              positionY: item.windowPositionY || _vm.getRandomY(), // Number
              center: item.center,
            })}}},[(index === _vm.dividerIndex)?_c('div',{staticClass:"divider"}):_vm._e(),_c('div',{staticClass:"tooltip flex-center absolute"},[_c('span',{staticStyle:{"text-wrap":"nowrap"}},[_vm._v(_vm._s(item.label))]),(item.newtab)?_c('div',{staticClass:"newtab"}):_vm._e()]),_c('div',{staticClass:"dock-icon",style:(("background-image: url('" + (require(("@/assets/icons/" + (item.icon)))) + "')"))}),_c('div',{staticClass:"active-indicator",style:(_vm.activeWindows.includes(item.label)
            ? 'height: 4px; opacity: 1;'
            : 'height: 0px; opacity: 0;')})])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }